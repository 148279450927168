import { render, staticRenderFns } from "./faceAuthorizationTable.vue?vue&type=template&id=739d4681&scoped=true&"
import script from "./faceAuthorizationTable.vue?vue&type=script&lang=js&"
export * from "./faceAuthorizationTable.vue?vue&type=script&lang=js&"
import style0 from "./faceAuthorizationTable.vue?vue&type=style&index=0&id=739d4681&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "739d4681",
  null
  
)

export default component.exports